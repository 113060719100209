import clsx from 'clsx';
import {graphql, Link, PageProps} from 'gatsby';
import React from 'react';
import DefaultLayout from '../layouts/default';
import * as styles from './solution.module.scss';
import {GatsbyImage} from 'gatsby-plugin-image';
import {useEffect} from 'react';
import LeftArrow from '../svgs/left-arrow';
import HfSeo from '../components/hf-seo';

const SolutionPage = ({pageContext, location}: PageProps<null, Solution>) => {
  return (
    <DefaultLayout location={location} shouldHoldHeader>
      <div className={styles.title}>{pageContext.title}</div>

      <div className={styles.content}>
        <div dangerouslySetInnerHTML={{__html: pageContext.content}} />
      </div>
      <div className={styles.actions}>
        <Link className={styles.back} to="/solutions">
          <LeftArrow className={styles.arrow} /> <span>返回</span>
        </Link>
      </div>
    </DefaultLayout>
  );
};

export default SolutionPage;

export const Head = ({pageContext}: PageProps<null, Solution>) => (
  <HfSeo title={`${pageContext.title} | 唯视智能`} />
);
